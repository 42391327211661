import logger from "./MyLogger";

function call_backend_api(endpoint, method, params_json, set_loading=(x) => {}) {
  logger.log(`Calling Backend API: ${endpoint} with params: ${JSON.stringify(params_json)}`);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  set_loading(true)
  let api_endpoint_url = backendUrl + "?api=" + endpoint

  const fetchOptions = {
    method: method,
    headers: {
      //"Accept": "application/json",
    },
  };

  if (method === "POST") {
    fetchOptions.body = JSON.stringify(params_json);
    fetchOptions.headers["Content-Type"] = "application/json"
  }
  if (method === "GET" && params_json && Object.keys(params_json).length !== 0) {
    const queryParams = new URLSearchParams(params_json).toString();
    api_endpoint_url += "&" + queryParams;
  }

  return fetch(api_endpoint_url, fetchOptions)
    .then((res) => {
      if (!res.ok) {
        set_loading(false)
        logger.log("API call failed with status: " + res.status);
        throw new Error("API call failed with status: " + res.status);
      }
      return res.json();
    })
    .then((backend_response) => {
      logger.log(`Backend API ${endpoint} Response: ${JSON.stringify(backend_response, null, 2).replace(/(\r\n|\n|\r)/gm, "")}`);
      if (!backend_response.api_result) {
        set_loading(false)
        logger.log("Error: 'api_result' field is missing for request " + api_endpoint_url + "\n" + JSON.stringify(backend_response));
        throw new Error("API response error: 'api_result' field is missing for request " + api_endpoint_url + "\n" + JSON.stringify(backend_response));
      }
      if (backend_response.api_result !== "OK") {
        set_loading(false)
        logger.log("Error: API result is not OK: " + backend_response.api_result);
      }
      set_loading(false)
      return backend_response;
    })
    .catch((error) => {
      set_loading(false)
      logger.error(`Error: Calling Backend API Failed :( \n${JSON.stringify(error, null, 2)}`);
      throw error;
    });
}

export default call_backend_api;
