import { useContext, useEffect, useState } from "react";
import { LoginContext } from "./LoginContext";
import logger from "./MyLogger";
import call_backend_api from "./APIHandler";

const NotificationHandler = () => {
  const [notificationPermission, setNotificationPermission] = useState(null);
  const [isServiceWorkerRegistered, setIsServiceWorkerRegistered] =
    useState(false);

  const { googleUser } = useContext(LoginContext);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      logger.log("Registering service worker");
      navigator.serviceWorker
        .register("/my-service-worker.js")
        .then((registration) => {
          setIsServiceWorkerRegistered(true);
        })
        .catch((error) => {
          logger.error("Service Worker registration failed:", error);
        });
    }
  }, []);

  useEffect(() => {
    if (Notification.permission === "granted") {
      setNotificationPermission(Notification.permission);
    } else {
      Notification.requestPermission().then((permission) => {
        setNotificationPermission(permission);
      });
    }
  }, []);



  const aqcuireAndupdateBackendWithSubscriptionRecord = (curGoogleUser, curIsServiceWorkerRegistered, curNotificationPermission) => {
    logger.log("In Subpscription Record Update Function. User: ", curGoogleUser, "srvcwrkr=registered: ", curIsServiceWorkerRegistered, "permission: ", curNotificationPermission)
    if (typeof curGoogleUser === 'undefined' || curGoogleUser === null) {
      return;
    }
    if (curIsServiceWorkerRegistered === false) {
      return;
    }
    if (curNotificationPermission !== "granted") {
      return;
    }
    try {
      logger.log("NotificationHandler: Fetching subscription record");
      
      if ("serviceWorker" in navigator) {
        
        navigator.serviceWorker.getRegistration().then((registration) => {
          if (!registration) {
            logger.log("NotificationHandler: No service worker registration found");
            return;
          }

          registration.pushManager.getSubscription().then((curSubscription) => {
            if (curSubscription === null) {
              logger.log("NotificationHandler: No subscription found, subscribing to google");
  
              registration.pushManager
                .subscribe({
                  userVisibleOnly: true,
                  applicationServerKey:
                    "BGXj9GWYciePeItvO_RJSWbroFDy46orHKB5gvRF_S2LNhrGaP9Ri6acNwqJRrQN7zXlXWqPqhHIKZa0EfOBBbE", // Replace with your own key.
                })
                .then((subscription) => {
                  logger.log("NotificationHandler: Subscribed successfully. Updating backend with new subscription ", subscription);
                  call_backend_api("update_notification_subscription_record", "POST", {id_token: curGoogleUser, subscription_record: subscription});

                })
                .catch((error) => {
                  logger.log("Google subscription failed:", error);
                });
  
            } else {
              logger.log("NotificationHandler: Subscription found from pushManager. Updating backend with new subscription ",
                curSubscription
              );
              call_backend_api("update_notification_subscription_record", "POST", {id_token: curGoogleUser, subscription_record: curSubscription});
            }
              
          }).catch((error) => {
            logger.log("Error retrieving subscription from pushManager:", error);
          });
          
        }).catch((error) => {
          logger.log("Error retrieving service worker registration:", error);
        });
        
      } else {
        logger.log("Service workers are not supported in this browser.");
      }
        
    } catch (error) {
      logger.error("Push subscription failed:", error);
    }
      
  };  

  useEffect(() => {
    aqcuireAndupdateBackendWithSubscriptionRecord(googleUser, isServiceWorkerRegistered, notificationPermission)
  }, [notificationPermission, googleUser, isServiceWorkerRegistered,]);


}

export {NotificationHandler, };
