import React, { createContext, useEffect, useState } from "react";
import logger from './MyLogger';
import { jwtDecode } from 'jwt-decode';

const clientId = "427936102880-2kcq2secru3q9083kqf29hcnevvnke9n.apps.googleusercontent.com";

const LoginContext = createContext(); // Context object

const LoginProvider = ({ children }) => {

  const [isLibInitialized, setIsLibInitialized] = useState(false); // Track if the prompt was already displayed
  const [googleUser, setGoogleUser] = useState(null);
  const [isPromptDisplayed, setIsPromptDisplayed] = useState(false); // Track if the prompt was already displayed

  const isTokenValid = (token) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      return decoded.exp > currentTime;
    } catch (error) {
      console.error("Failed to decode the token:", error);
      return false;
    }
  };

  const initializeGoogle = () => {
    window.google.accounts.id.initialize({
      client_id: clientId,
      callback: handleCredentialResponse, // will be called when prompt is called
      //auto_select: false, // Disable One Tap
      auto_select: true,
      context: 'signin'
    });
  };

  useEffect(() => {
      console.log("GOOGLE: in useEffect()")

      if (isLibInitialized === false) {
        console.log("GOOGLE: init google lib")
        initializeGoogle();
        setIsLibInitialized(true)
      }

      const storedToken = localStorage.getItem('access_token');
      if (storedToken) {
        if (!isTokenValid(storedToken)) {
          logger.log("GOOGLE: Found token in storage but its not valid");
          window.google.accounts.id.prompt() // use refresh token to get a new token. supposed to be silent
        }
        logger.log("GOOGLE: Found a valid token in storage");
        setGoogleUser(storedToken);
      }

  }, []);

  const handleCredentialResponse = (response) => {
    const idToken = response.credential;
    logger.log("GOOGLE: In handleCredentialResponse")
    logger.log(response)
    if (isTokenValid(idToken)) {
      setGoogleUser(idToken);
      localStorage.setItem('access_token', idToken);
      logger.log("GOOGLE: Google Sign-in successful");
    } else {
      logger.error("GOOGLE: Invalid Token Received: ", idToken);
    }
  };

  const handleLogin = () => {
    logger.log("GOOGLE: In handleLogin")
    const storedToken = localStorage.getItem('access_token');
    if (storedToken && isTokenValid(storedToken)) {
      logger.log("GOOGLE: Found token in storage");
      setGoogleUser(storedToken);
    } else {
      // If the prompt has already been shown, don't show it again
      if (isPromptDisplayed) return;

      setIsPromptDisplayed(true); // Mark that the prompt is being shown
      logger.log("GOOGLE: Starting prompt");

      window.google.accounts.id.prompt((notification) => {
        if (notification.isNotDisplayed()) {
          console.log("GOOGLE: Prompt not displayed. Reason:", notification.getNotDisplayedReason());
        } else if (notification.isSkippedMoment()) {
          console.log("GOOGLE: User skipped the prompt.");
        } else {
          console.log("GOOGLE: Prompt displayed successfully.");
        }
        // Reset prompt state after processing
        setIsPromptDisplayed(false);
      });
    }
  };
const renderLoginButton = (divId) => {
  if (isLibInitialized === false) {
    initializeGoogle();
    setIsLibInitialized(true)
  }
  window.google.accounts.id.renderButton(
    document.getElementById(divId),
    { theme: "outline", size: "large" }  // customization attributes
  );
}

  const handleLogout = () => {
    setGoogleUser(null);
    localStorage.removeItem('access_token');
    window.google.accounts.id.disableAutoSelect();
    logger.log("User logged out.");
  };

  return (
    <LoginContext.Provider value={{ googleUser, renderLoginButton, handleLogin, handleLogout }}>
      {children}
    </LoginContext.Provider>
  );
};

export { LoginContext, LoginProvider };
